import {useState} from "react";
import Transactions from "./Transactions";
import Account from "./Account";
import Wallet from "../Shop/Wallet";

export default function Tabs() {
    const [activeTab, setActiveTab] = useState(0);
    const content = [<Account/>, <Wallet/>, <Transactions/>];
    const tabs = ["Account", "Wallet", "Transactions"];

    return (
        <div className="w-full mx-auto text-center pl-[10px] pr-[10px]">
            <div className="flex items-center gap-2">
                {tabs.map((tab, index) => (
                    <button
                        key={index}
                        onClick={() => {
                            setActiveTab(index);
                        }}
                        className={`w-[104px] h-[30px] flex justify-center items-center text-[14px] font-anton font-[400] ${
                            activeTab === index ? "bg-lime text-main" : "bg-white text-main"
                        }`}
                    >
                        {tab}
                    </button>
                ))}
            </div>
            <div className="p-2 border border-gray-300 border-t-0 bg-linear-gardient">
                {content[activeTab]}
            </div>
        </div>
    );
}
