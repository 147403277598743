import React from "react";

import Tabs from "./Tabs";

export const Account = () => {
  return (
    <div id="content" className="min-h-[75vh] mt-[0px] md:mt-[20px] content-t">
      <div className="flex-grow">
        <h1 className="text-[30px] md:text-[55px] !text-[#d2ff00] pl-[10px]">
          MY ACCOUNT
        </h1>
      </div>
      <Tabs />
    </div>
  );
};
export default Account;
